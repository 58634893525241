import React from 'react'
import { Helmet } from 'react-helmet'

/* COMPONENTS */

import Layout from '../components/Layout/index'
import './diaexpress/diaexpress.scss'

const Banner = () => (
  <>
    <section className="container-fluid p-0 d-md-block d-none">
      <div className="backgroud-banner-diaxpress w-100">
        <div className="container d-flex justify-content-start">
          <div className="banner-info">
          </div>
        </div>
      </div>
    </section>
    <section className="container-fluid p-0 d-md-none d-block">
      <div className="backgroud-banner w-100"></div>
      <div className="container d-flex justify-content-center my-4">
        <div className="banner-info  d-flex flex-column align-items-center m-0 p-0">
        </div>
      </div>
    </section>
  </>
)

const ProtecaoDados = () => {
  const breadC = {
    visible: true,
    color: 'black',
    items: [
      { path: '/', name: 'Home' },
      { path: '/clubdia/termos/', name: 'Termos' }
    ]
  }
  const conteudoTabela = [
    {
      direito: 'Confirmação da existência de tratamento',
      prazo: 'Em até 15 dias corridos a partir do recebimento da requisição'
    },
    {
      direito: 'Acesso',
      prazo: 'Em até 15 dias corridos a partir do recebimento da requisição'
    },
    {
      direito: 'Correção',
      prazo: 'Em até 15 dias úteis a partir do recebimento da requisição'
    },
    {
      direito: 'Anonimização, bloqueio ou eliminação',
      prazo: 'Em até 15 dias corridos a partir do recebimento da requisição'
    },
    {
      direito: 'Portabilidade',
      prazo: 'Em até 15 dias corridos a partir do recebimento da requisição'
    },
    {
      direito: 'Revogação ou não fornecimento do consentimento',
      prazo: 'Em até 15 dias corridos a partir do recebimento da requisição'
    },
    {
      direito: 'Revisão de decisões automatizadas',
      prazo: 'Em até 15 dias corridos a partir do recebimento da requisição'
    },
    {
      direito: 'Eliminação dos Dados tratados com consentimento',
      prazo: 'Em até 15 dias corridos a partir do recebimento da requisição'
    },
    {
      direito: 'Oposição',
      prazo: 'Em até 15 dias úteis a partir do recebimento da requisição'
    }
  ]
  return (
    <Layout breadCrumb={breadC}>
      <Helmet>
        <html lang="pt-BR" amp />
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Políticas Corporativas Proteção de Dados | DIA Supermercados</title>
        <meta name="description" content="Termos e Condições do ClubDIA. Última atualização 28/04/2022." />
        <meta name="keywords" content="termos e condicoes, clubdia" />
        <meta name="author" content="DIA Supermercados" />
      </Helmet>
      <Banner />
      <section className='container'>
        <div className="row">
          <div className="col-12">
            <p className='pt-lg-3 p-0 text-muted'><span className='font-size-12'>ÚLTIMA ATUALIZAÇÃO: 18/07/2023</span></p>
            <h1 className='text-red pt-2 pb-1 '>
              Política de Direito dos Titulares
            </h1>
            <p className='font-size-1 pb-2'>
              <strong>DIA BRASIL SOCIEDADE LTDA.</strong>, inscrita no <strong>CNPJ nº 03.476.811/0001-51</strong>, com sede Avenida
              Ibirapuera, 2332, Bairro Indianópolis, na cidade de São Paulo, Estado de São Paulo, CEP:
              04028-002 (“<strong>DIA</strong>” ou “nós”) tem o compromisso de proteger os Dados Pessoais de seus
              colaboradores, usuários, clientes, fornecedores, parceiros de negócios e demais pessoas com as
              quais mantemos algum tipo de relacionamento (“<strong>você</strong>”, “<strong>seu</strong>” ou “<strong>sua</strong>”). Por esse motivo,
              disponibilizamos a você a presente Política de Direitos do Titular de Dados Pessoais (“<strong>Política de
              Direitos</strong>”), que deve ser lida em conjunto com a política de privacidade aplicada a você.
            </p>
            <p className='font-size-1 pb-4'>
              Esta Política é parte integrante do programa de privacidade e proteção de dados pessoais
              desenvolvido pelo DIA e descreve a abordagem adotada pelo DIA ao atender os direitos dos
              titulares de dados, de acordo com a Lei nº 13.709/2018 (“<strong>Lei Geral de Proteção de Dados
              Pessoais</strong>” ou “<strong>LGPD</strong>”).
            </p>
          </div>
          <div className="col-12">
            <ol className="title p-0">
              <li className="numberTitle"><span className='font-size-18'>DEFINIÇÕES</span>
                <ol>
                  <li className='font-size-1'>Os seguintes termos e definições foram usados nesse documento:
                    <ul className='letterList text-dark'>
                      <li className='font-size-1'>
                        <strong>Dados Pessoais:</strong> significa qualquer informação que, direta ou indiretamente, identifique ou
                          possa identificar uma pessoa natural, como por exemplo, nome, CPF, data de nascimento,
                          dentre outros. Incluem-se neste conceito os Dados Sensíveis e Dados de Crianças (conforme
                          abaixo definido). Para fins de esclarecimento, informações relacionadas a pessoas jurídicas, tais
                          como CNPJ, endereço da matriz etc., não são consideradas Dados Pessoais;
                      </li>
                      <li className='font-size-1'>
                        <strong>Dados Pessoais Sensíveis:</strong> significa qualquer informação que revele, em relação a uma
                          pessoa natural, origem racial ou étnica, convicção religiosa, opinião política, filiação a sindicato
                          ou a organização de caráter religioso, filosófico ou político, dado referente à saúde ou à vida
                          sexual, dado genético ou biométrico;
                      </li>
                      <li className='font-size-1'>
                        <strong>Dados de Crianças:</strong> significa qualquer informação que, direta ou indiretamente, identifique
                          ou possa identificar uma criança (assim entendido a pessoa até doze anos de idade
                          incompletos);
                      </li>
                      <li className='font-size-1'>
                        <strong>Encarregado:</strong> É a pessoa indicada pelo controlador (no caso, o DIA) e operador para atuar
                          como canal de comunicação entre o controlador, os Titulares dos dados e a Autoridade Nacional
                          de Proteção de Dados.
                      </li>
                    </ul>
                  </li>
                </ol>
              </li>
              <li className="numberTitle"><span className='font-size-18'>O TITULAR DE DADOS PESSOAIS</span>
                <ol >
                  <li className='font-size-1'>A LGPD tem como premissa garantir ao titular dos dados pessoais o seu efetivo poder e
                  controle sobre tais dados. Desta forma, a lei estabelece que é assegurada às pessoas naturais a
                  quem se referem os dados pessoais que são objeto de tratamento (“<strong>Titular de Dados</strong>” ou
                  “<strong>Titular</strong>”), o exercício de alguns direitos, conforme exploraremos no tópico 4 (“<strong>Direitos</strong>”), a seguir.</li>
                </ol>
              </li>
              <li className="numberTitle"><span className='font-size-18'>CANAIS DE CONTATO PARA EXERCER OS SEUS DIREITOS</span>
                <ol>
                  <li className='font-size-1'>Para exercer os seus Direitos, o Titular de Dados deverá entrar em contato com nosso(s)
                  Encarregado(s) Tássia Siqueira Silva e Fernando Modolo Romero, por meio do e-mail
                  <strong>dpo.brasil@diagroup.com</strong> ou correspondência ao endereço <strong>Avenida Ibirapuera, 2332 -
                  Indianópolis - São Paulo - SP - CEP 04028-002</strong> ou através do canal <a href='https://diasupermercados-privacy.my.onetrust.com/webform/e8ee1561-5f69-4f41-ab56-108e1791052c/8e8e6d7f-edff-42ab-9d22-631e5b3e4b77' className='text-red text-underline'>Canal do DPO</a>.
                  </li>
                  <li className='font-size-1'>A indicação do nome do nosso(s) Encarregado(s) estará sempre disponível no site <a href='https://www.dia.com.br/' className='text-red text-underline'>www.dia.com.br</a>.</li>
                </ol>
              </li>
              <li className="numberTitle" id='teste-lista-ul'><span className='font-size-18'>DIREITOS</span>
                <ol>
                  <li className='font-size-1'>
                    A seguir, estão elencados os Direitos previstos na LGPD:
                    <ul className='circleList text-dark'>
                      <li><u>Confirmação da existência de tratamento</u>: Este Direito permite que você possa
                        requisitar e receber uma confirmação sobre a existência de coleta, armazenamento,
                        compartilhamento ou qualquer tipo de tratamento realizado pelo DIA com relação aos seus
                        Dados Pessoais.
                      </li>
                      <li><u>Declaração clara e completa sobre o tratamento de dados pessoais</u>: Este Direito
                        permite que você requisite e receba uma cópia gratuita e em formato acessível dos Dados
                        Pessoais tratados por nós.
                      </li>
                      <li><u>Correção de dados incompletos, inexatos ou desatualizados</u>: Caso você identifique
                        alguma incorreção ou imprecisão nos seus Dados Pessoais que tratamos, você poderá requerer
                        ao DIA que atualize os seus Dados Pessoais.
                      </li>
                      <li><u>Anonimização, bloqueio ou eliminação dos Dados Pessoais desnecessários, excessivos
                        ou tratados em desconformidade com a legislação</u>: Caso o Titular de Dados queira que
                        deixemos de realizar o tratamento dos seus Dados Pessoais por entender que o tratamento viola
                        a LGPD, o Titular de Dados poderá solicitar que adotemos uma das 3 (três) medidas a seguir:
                      </li>
                      <ul className='bulletsList text-dark'>
                        <li>anonimização, que, em resumo, implica na continuidade do tratamento dos dados desde que eles não possam mais ser associados a você;</li>
                        <li>bloqueio, que garante que os Dados Pessoais não serão mais utilizados por nós, mas continuarão nos sistemas em que estão inseridos; ou</li>
                        <li>eliminação, que consiste na exclusão dos Dados Pessoais de nossos sistemas</li>
                      </ul>
                      <li><u>Eliminação dos Dados Pessoais tratados com consentimento</u>: Caso Você nos tenha
                        fornecido o seu consentimento para uma finalidade de tratamento, você pode requisitar a
                        eliminação dos Dados Pessoais que armazenamos.
                      </li>
                      <li><u>Portabilidade</u>: O Titular de Dados poderá solicitar que o DIA envie os seus Dados
                        Pessoais para outra entidade por ele determinada. A portabilidade não significa que os dados
                        serão excluídos de nossa base. Esse direito somente poderá ser exercido de acordo com a
                        regulamentação da Autoridade Nacional de Proteção de Dados (“<b>ANPD</b>”) e observados os
                        segredos comercial e industrial do DIA.
                      </li>
                      <li><u>Informação das entidades públicas e privadas com as quais o DIA realizou uso
                        compartilhado de Dados Pessoais</u>: O Titular de Dados tem o direito de saber com quem os seus
                        Dados Pessoais estão sendo compartilhados. Tal informação consta de forma genérica em
                        nossos avisos e políticas de privacidade, e serão informados em maior detalhe quando da
                        solicitação pelo Titular de Dados Pessoais.
                      </li>
                      <li><u>Revogar ou não fornecer o consentimento</u>: Você tem o Direito de revogar o seu
                        consentimento ou, se for o caso, de não nos fornecê-lo e ser informado sobre as consequências
                        desta negativa. É possível que da negativa de consentimento ou da sua revogação decorra a
                        impossibilidade de lhe prover determinados produtos ou serviços.
                      </li>
                      <li><u>Revisão de decisões automatizadas</u>: Você tem o Direito de solicitar a revisão de
                        decisões tomadas unicamente com base em tratamento automatizado dos Dados Pessoais e de
                        receber informações claras e adequadas a respeito dos critérios utilizados na decisão, desde que
                        essas informações não violem qualquer segredo comercial do DIA.
                      </li>
                      <li><u>Oposição ao tratamento</u>: Você também tem o direito de se opor a determinadas
                        finalidades de tratamento de Dados Pessoais. Por exemplo, caso o DIA colete Dados Pessoais
                        tornados manifestamente públicos por você (em uma rede social, como o LinkedIn, por
                        exemplo, para fazer contato com você sobre uma oportunidade de emprego), você pode
                        requerer que o Dia pare de realizar esse tratamento.
                      </li>
                      <li><u>Contatar a ANPD</u>: O Titular dos Dados Pessoais tem o direito de peticionar à ANPD
                        caso o DIA não atenda às suas solicitações, por exemplo.
                      </li>
                    </ul>
                  </li>
                </ol>
              </li>
              {/* <div className='w-50'></div> */}
              <li className="numberTitle"><span className='font-size-18'>ANÁLISE DAS REQUISIÇÕES DOS TITULARES</span>
                <ol className=''>
                  <li className='font-size-1'>
                    <p>O Dia tratará individualmente cada solicitação de direitos dos Titulares de Dados, de acordo com o seguinte:</p>
                    <ul className='blackDiamond text-dark mexendo-mobile-protecao'>
                      <li className='font-size-1'><span className='font-size-15'>Comprovação de identidade</span>
                        <p className='ml-4'>Talvez seja necessário solicitar informações específicas para confirmar a identidade e garantir a
                          possibilidade de o Titular exercer os seus Direitos. Esta é uma medida de segurança para
                          assegurar que os Dados Pessoais não sejam divulgados a qualquer pessoa que não tenha
                          legitimidade para recebê-los.
                        </p>
                        <p className='pt-2 ml-4'>Usualmente não coletamos dados de menores de 18 anos. Mas, se for o caso, os pais, mães ou
                          responsáveis legais poderão exercer os Direitos em nome de crianças ou de adolescentes.
                          Também poderemos solicitar documentos e informações que comprovem que há essa relação
                          de representação.
                        </p>
                      </li>
                      <li className='font-size-1'><span className='font-size-15'>Análise da requisição</span>
                        <p className='ml-4'>Quando recebermos uma requisição relativa aos direitos dos titulares, nosso time a analisará e
                          poderá responder o Titular de duas maneiras, determinando que: (i) o pedido é lícito e legítimo,
                          devendo, portanto, ser acatado; ou (ii) que pedido foi negado e, por determinadas razões, não
                          poderá ser acatado.
                        </p>
                        <p className='pt-2 ml-4'>O DIA informará o Titular de Dados mesmo em caso de negativa, esclarecendo os motivos pelos
                          quais o seu pedido não foi aprovado. Todas as nossas respostas serão sempre enviadas para o
                          mesmo e-mail utilizado pelo Titular de Dados para nos contatar.
                        </p>
                      </li>
                      <li className='font-size-1'><span className='font-size-15'>Situações em que poderemos negar o Direito</span></li>
                    </ul>
                    <p className='ml-4'>Os Direitos dos Titulares não são ilimitados e, por isso, apresentamos abaixo algumas situações
                      que podem levar à negativa total ou parcial pelo DIA:
                    <ul className='bulletsList text-dark'>
                      <li className='font-size-1'>
                        preservação de segredo de negócio e propriedade intelectual do DIA
                      </li>
                      <li className='font-size-1'>
                        violação de direitos e liberdades de terceiros
                      </li>
                      <li className='font-size-1'>
                        as informações estão anonimizadas e, portanto, neste caso a
                        identificação/localização dos dados não será possível
                      </li>
                      <li className='font-size-1'>
                        obstrução à lei e à justiça
                      </li>
                      <li className='font-size-1'>
                        nos casos de requisições repetitivas, reiteradas ou excessivas que versem sobre o
                        mesmo assunto, o Dia irá atender a primeira solicitação efetuada, sempre respeitando o
                        prazo legal,
                      </li>
                    </ul>
                      De toda forma, responderemos às requisições em tempo e iremos informar o Titular com
                      clareza sobre os motivos que nos levaram à eventual negativa do seu pedido.
                    </p>
                    <ul className='blackDiamond text-dark'>
                      <li className='font-size-1 lista-ul-protecao-dados'><span className='font-size-15'>Contato com terceiros com os quais compartilhamos Dados Pessoais</span>
                        <p className='ml-4'>Caso o pedido seja aceito, faremos o possível para contatar os nossos fornecedores e parceiros
                          comerciais que podem ter acesso aos Dados Pessoais do Titular requisitante para que eles
                          também retifiquem, eliminem ou exerçam qualquer outro Direito que o Titular nos requisitou.
                        </p>
                      </li>
                      <li className='font-size-1 lista-ul-protecao-dados'><span className='font-size-15'>Prazo para resposta à uma solicitação</span>
                        <p className='mb-3 ml-4'>Recebida a solicitação para o exercício dos Direitos, o DIA responderá o Titular dentro dos seguintes prazos:</p>
                        <div className='d-flex justify-content-center'>
                          <div id="tabela-produto" className='position-relative'>
                            <table>
                              <thead>
                                <tr>
                                  <th><p className='font-Yanone font-size-15'>Direito do Titular</p></th>
                                  <th><p className='font-Yanone font-size-15'>Prazo</p></th>
                                </tr>
                              </thead>
                              <tbody>
                                {conteudoTabela.map((pos, index) => {
                                  return (
                                    <>
                                      <tr
                                        key={index}
                                        className={
                                          index % 2 !== 0 ? 'linha-par' : 'linha-impar'
                                        }>
                                        <td><strong>{pos.direito}</strong></td>
                                        <td>{pos.prazo}</td>
                                      </tr>
                                    </>
                                  )
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className='posicao-caso-sejam-mobile'>
                          <p className='ml-3'>Caso sejam necessários esclarecimentos ou mais informações sobre o pedido do Titular,
                            podemos enviar ao Titular alguns questionamentos para que possamos responder à sua
                            requisição de maneira satisfatória..
                          </p>
                        </div>
                      </li>
                      <li className='font-size-1'>
                        <span className='font-Yanone font-size-15'>Armazenamento da solicitação<br></br></span>
                        <p className='ml-4'>Poderemos armazenar e manter, em forma de registro, um histórico das requisições de Direitos
                          que o Titular realizou perante o DIA para que possamos, se necessário, apresentá-lo às
                          autoridades competentes, tal como para a ANPD, como prova de que respondemos os pedidos
                          da maneira adequada, conforme LGPD.
                        </p>
                      </li>
                    </ul>
                  </li>
                </ol>
              </li>
              <li className="numberTitle"><span className='font-size-18'>ATUALIZAÇÕES DESTA POLÍTICA</span>
                <ol>
                  <li className='font-size-1'>
                  A Política de Direitos pode passar por atualizações. Desta forma, recomendamos que Você
                  visite periodicamente esta página para ter conhecimento sobre as alterações realizadas.
                  </li>
                </ol>
              </li>
            </ol>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default ProtecaoDados
